import IncidentHoverWrapper from "./IncidentHoverWrapper";
import Markdown from "components/shared/Markdown";
import React from "react";
import Timestamp from "./Timestamp";
import { StatusIcons } from "../Icons";
import { statusColors } from "v2/constants/colors";
import {
  getLatestMilestoneFromTimestamps,
  getLatestNoteEvent,
  getNoteFromEvent,
} from "v2/helpers/timeline";
import Tag from "./Tag";
import { usePayloadContext } from "components/usePayloadContext";

const BannerIncident = ({
  bannerType,
  title,
  latestNote,
  incident = {},
  startTime,
  endTime,
}) => {
  const { config } = usePayloadContext();
  const StatusIcon = StatusIcons[bannerType];
  const bannerColor = statusColors[bannerType];

  const incidentLatestNoteEvent = getLatestNoteEvent(incident?.timeline);
  const incidentLatestNote = getNoteFromEvent(incidentLatestNoteEvent);
  const milestone = getLatestMilestoneFromTimestamps(incident?.timestamps);
  const showSeverity = config?.exposedFields?.includes("default/severity");
  const showPriority = config?.exposedFields?.includes("default/priority");
  const severity = incident?.severitySlug;
  const priority = incident?.priority;

  return (
    <div
      className={`flex w-full border bg-white border-fh-gray-6 dark:bg-fh-gray-dark-3 dark:border-fh-gray-dark-6 dark:opacity-90 rounded mb-4 ${
        incident?.id ? "dark:hover:bg-fh-gray-dark-6" : ""
      } overflow-hidden`}
    >
      <div
        className={`bg-fh-${bannerColor} p-1 rounded-l relative mt-[-1px]`}
      />
      <IncidentHoverWrapper incidentId={incident?.id}>
        <div
          className={`flex flex-col w-full py-6 px-4 bg-white dark:bg-fh-gray-dark-3
        ${
          incident?.id
            ? " dark:hover:bg-fh-gray-dark-6 hover:bg-fh-gray-light-1"
            : ""
        }
        `}
        >
          <div className="flex w-full font-medium items-center space-x-4">
            <StatusIcon
              className={`w-[32px] h-[32px] text-fh-${bannerColor}`}
            />
            <div className="grid gap-0">
              <h3 className="text-lg leading-1">{incident?.title || title}</h3>
              <div className="my-2 flex">
                {milestone && (
                  <Tag
                    size="sm"
                    className="capitalize w-fit"
                    variant="milestone"
                  >
                    {milestone}
                  </Tag>
                )}
                {showSeverity && (
                  <Tag size="sm" variant="lighter" className="capitalize w-fit">
                    {severity}
                  </Tag>
                )}
                {showPriority && (
                  <Tag size="sm" variant="lighter" className="capitalize w-fit">
                    {priority}
                  </Tag>
                )}
              </div>
              {(incidentLatestNote || latestNote) && (
                <div className="text-base font-normal text-fh-gray-11 dark:text-fh-gray-dark-11">
                  <div className="flex flex-col gap-8">
                    <Markdown source={incidentLatestNote || latestNote} />
                  </div>
                </div>
              )}
              {startTime && (
                <div className="flex mt-4 text-fh-gray-11 dark:text-fh-gray-dark-11 text-sm">
                  <p className="text-base mr-2 text-sm">Start time:</p>
                  <Timestamp timestamp={startTime} />
                </div>
              )}
              {endTime && (
                <div className="flex text-fh-gray-11 dark:text-fh-gray-dark-11 text-sm">
                  <p className="text-base mr-2 text-sm">End time:</p>
                  <Timestamp timestamp={endTime} />
                </div>
              )}
            </div>
          </div>
        </div>
      </IncidentHoverWrapper>
    </div>
  );
};

export default BannerIncident;
